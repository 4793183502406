const Bookmark = {
  setup (resource_type, resource_id) {
    this.resource_type = resource_type;
    this.resource_id = resource_id;
    this.originals = [];
    this.form = document.querySelector('form#bookmark_form');
    this.select = this.form.querySelector('#list_select');
    this.add_button = this.form.querySelector('#add-bookmark');
    this.field = this.form.querySelector('#new_list');
   
    if(current_user.id != undefined){
      this.render_lists();
      this.originals = this.update_group_ids('originals',[]);
      this.add_button.onclick = (event)=> {
        this.create_bookmark(this, event);
      }
    }
  },

  update_group_ids(name, array){
     document.querySelectorAll("input[name='group_id[]']").forEach((el, i) =>{
      var group_id = parseInt(el.value);
      var list = current_user.lists.find((list) => list.id == group_id);
      if (el.checked && !list.list_items.includes(this.resource_id) && !array.includes(group_id)){
        list.list_items.push(this.resource_id);
        list.last_updated_at = (new Date() / 1000);
        array.push(group_id);
      } else if (!(el.checked) && list.list_items.includes(this.resource_id)) {
        array.push(group_id);
        list.list_items = list.list_items.filter(e => e !== this.resource_id);
      }
    });
    return array;
  },

  render_lists () {
    this.list_options = document.querySelector('#list_multiselect_options');
    this.list_options.innerHTML = ""
   
    current_user.lists.forEach((item, i) => {
      if(item.name != undefined){
        let selected = item.list_items.includes(parseInt(this.resource_id))
        let html = `<li class="${selected ? 'active' : 'false'}">
          <label class='flex items-center' value='${item.name}' for='list_id_${item.id}'>
            <div class='list-image'><img src='${item.cover_photo_url}?w=50&h=50&fit=crop'></div>
            <input type='checkbox' class='hidden' name='group_id[]' id='list_id_${item.id}' value='${item.id}' ${(selected ? 'checked' : '')} data-name="${item.name}">
            ${item.name}
          </label>
        </li>`
        document.querySelector('ul#list_multiselect_options').innerHTML += html;
      }
    })
    document.querySelectorAll('#list_multiselect_options input').forEach((input) => {
      input.addEventListener('change', (event) => {
        if(event.target.checked){
          event.target.closest('li').classList.add('active')
        } else {
          event.target.closest('li').classList.remove('active')
        }
      })
    })
  },

  create_bookmark(_this, e){

    request = (changes, callback) => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', "/api/bookmarks");
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.onload = function() {
        if (xhr.status === 201 || xhr.status === 202) { 
          let data = JSON.parse(xhr.responseText)
          callback(data);
        }
      };
      xhr.send(JSON.stringify({
        group_id: changes,
        resource_type: _this.resource_type,
        resource_id: _this.resource_id
      }));
    }

    e.preventDefault();
    let changes = _this.update_group_ids('changes', _this.originals);
    if((changes.length > 0)){
      request(changes, (data)=> {
        let btn = document.querySelectorAll(`.btn-add-bookmark[data-resource-id='${data.resource_id}'],.btn-save-bookmark[data-resource-id='${data.resource_id}']`);
        btn.forEach(b => {b.classList.add('active') });
        Modal.close();
      })
    }else{
      Modal.close();
    }
  },

  create_list (el, e) {

    request = (name, callback) => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', "/api/users/" + current_user.id + "/lists");
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.onload = function() {
        if (xhr.status === 200 || xhr.status === 201 || xhr.status === 202) { 
          let data = JSON.parse(xhr.responseText)
          data.cover_photo_url = 'https://images.theoutbound.com/assets/background_images/blank_placeholder.jpg';
          data.last_updated_at = (new Date() / 1000);
          current_user["lists"].push({id: data.id, name: data.name, slug: data.slug, private: 1, author_id: data.author_id, list_items: [], last_updated_at: data.last_updated_at, cover_photo_url: data.cover_photo_url});
          let li = document.createElement("li")
          li.className = 'active';
          li.innerHTML = "<label class='flex items-center' value='"+data.name+"' for='list_id_"+data.id+"'><div class='list-image'><img src='"+data.cover_photo_url+"?w=50&h=50&fit=crop'></div><input type='checkbox' class='hidden' checked='checked' name='group_id[]' id='list_id_"+data.id+"' value='"+data.id+"' data-name='"+data.name+"'>"+data.name+"</label></li>"
          document.querySelector('#list_multiselect_options').prepend(li);
          callback();
        }
      };
      xhr.send(JSON.stringify({
        list: {
          name: name
        },
        user_id: user_id
      }));
    }

    if(!!(window.getComputedStyle(this.field).display === "none")){
      this.field.classList.remove('hidden');
      this.select.classList.add('hidden');
      this.add_button.classList.add('hidden');
      el.innerHTML = "Create";
    } else {
      let name = this.field.querySelector("input").value;
      if (name.length > 0) {
        request(name, ()=> {
          this.field.classList.add('hidden');
          this.select.classList.remove('hidden');
          this.add_button.classList.remove('hidden');
          el.innerHTML = "New";
        });
      }
    }
  }
}
window.Bookmark = Bookmark;