function badBot(agent){
  let result = !!(agent.match(/(PetalBot)|(bingbot)|(Semrush)|(Facebot)|(Twitterbot)|(Pinterestbot)/))
  //console.log('badBot', result, agent)
  return result;
}

window.badBot = badBot;

function toParam (object) {
  var encodedString = '';
  for (var prop in object) {
      if (object.hasOwnProperty(prop)) {
          if (encodedString.length > 0) {
              encodedString += '&';
          }
          encodedString += encodeURI(prop + '=' + object[prop]);
      }
  }
  return encodedString;
}
window.toParam = toParam;

function goto_path (path) {
  return window.location.href = "/explore/" + path;
};
window.goto_path = goto_path;

function getMetaContent (atts = {name: 'csrf-token' }) {
  let selector = `meta${Object.keys(atts).map((k) => { return `[${k}="${atts[k]}"]`})}`;
  try{
    return document.querySelector(selector).getAttribute('content')
  } catch(e) {
    console.warn(`${selector} not found`)
  }
}

function setAuthenticityToken () {
  const token = getMetaContent({ name: "csrf-token" });
  const op = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function() {
      const resp = op.apply(this, arguments);
      const urlRegex = /^(https?:\/\/localhost:\d+|https?:\/\/[a-zA-Z0-9.-]+\.com)$/;
      //console.log("Local", !!(arguments[1] && urlRegex.test(arguments[1])), arguments[1]);
      if (!!(arguments[1] && urlRegex.test(arguments[1]))) {
        this.setRequestHeader('X-CSRF-Token', token);
      }
      return resp;
    };
  document.querySelectorAll('input[name=authenticity_token]').forEach(input => {
    input.value = token;
  })
}

function openTabs () {
  document.querySelectorAll('article .summary a, article .information a, article .adventure-description a, article .description a, .contest .details a').forEach((link)=>{
    link.onclick = (event) => {
      const url = event.target.href || event.currentTarget.href;
      if (url) {
        event.preventDefault();
        return window.open(url);
      }
    }
  });
}

Object.compact = (obj) => {
  return Object.keys(obj)
  .filter( key => !!(obj[key]) )
  .reduce( (res, key) => Object.assign(res, { [key]: obj[key] }), {} );
}

String.prototype.slugify = function() {
  return this.toLowerCase().replace(/\W/g, '-');
};

String.prototype.query_params = function() {
  var params, qs, re, tokens;
  qs = this.split("+").join(" ");
  params = {};
  re = /[?&]?([^=]+)=([^&]*)/g;
  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }
  return params;
};


function validate_field (el) {
  if (el.checkValidity()) {
    el.classList.remove('validation-error');
  } else {
    el.classList.add('validation-error');
  }
};

function $HTML(string){
  return document.createRange().createContextualFragment(string).firstChild;
}

function setInnerHTML (elm, html) {
  elm.innerHTML = html;
  Array.from(elm.querySelectorAll("script")).forEach( oldScript => {
    const newScript = document.createElement("script");
    Array.from(oldScript.attributes)
      .forEach( attr => newScript.setAttribute(attr.name, attr.value) );
    newScript.appendChild(document.createTextNode(oldScript.innerHTML));
    oldScript.parentNode.replaceChild(newScript, oldScript);
  });
}

function toggle(selector) {
  let el = document.querySelector(selector);
  el.style.display = !!(el.style.display == 'block') ? 'none' : 'block';
}

function setupRatingStars() {
  const update_rating = (rating) => {
    document.querySelectorAll("#bookmark_stars .fa-star").forEach((star, i) => {
      if (i < rating) {
        star.classList.add('color-gold');
      } else {
        star.classList.remove('color-gold');
      }
    });
  };

  const input = document.querySelector('#bookmark_value');
  if (!input) return; // Guard clause if elements don't exist

  const rating = Number(input.value);
  update_rating(rating);

  const form = document.querySelector('form');
  if (form) {
    form.onsubmit = (e) => {
      e.preventDefault();
      Modal.close();
    };
  }

  const stars = document.querySelectorAll('#bookmark_stars .fa-star');
  stars.forEach((star, i) => {
    star.onmouseover = () => { update_rating(i+1); };
    star.onmouseout = () => { update_rating(Number(input.value)); };
    star.onclick = () => {
      input.value = i+1;
      update_rating(input.value);
    };
  });
}

window.toggle = toggle;
window.setInnerHTML = setInnerHTML;
window.getMetaContent = getMetaContent;
window.validate_field = validate_field;
window.$HTML = $HTML;
window.setupRatingStars = setupRatingStars;

Events.add(document,'userReady', function(e){
  setAuthenticityToken();
  openTabs();
})
if (typeof module !== 'undefined'){
  module.exports = { 
    toParam, goto_path, getMetaContent, setAuthenticityToken, 
    openTabs, validate_field, $HTML, setInnerHTML 
  }
}